<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <draggable
                v-model="images"
                group="myGroup"
                tag="div"
                class="row"
              >
                <b-col
                  v-for="(item, index) in images"
                  :key="index"

                  cols="4"
                >
                  <b-img
                    fluid-grow
                    :src="item"
                    rounded
                    class="mr-1 mb-1 imgs-show"
                    height="120"
                  />
                  <b-button
                    variant="danger"
                    class="btn-remove"
                    @click="removeImage(index)"
                  >{{ $t('Delete') }} ({{ index + 1 }})</b-button>
                </b-col>
              </draggable>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Images')"
                label-for="audios"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >{{ $t('Upload') }}</b-button>
                    <b-form-file
                      ref="refInputEl"
                      multiple
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImagesRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >{{ $t('Reset') }}</b-button>
                    <!--/ reset -->
                    <b-card-text>
                      {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                      {{ $t('Max size of') }} 600KB
                    </b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">

              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Audios')"
                label-for="audios"
              >
                <div
                  v-for="(item,index) in audios"
                  :key="index"
                  class="audio-item"
                >
                  <audio
                    class="imgs-show"
                    controls
                  >
                    <source
                      :src="item"
                      type="audio/mp4"
                    >
                    Your browser does not support the audio tag.
                  </audio>
                  <b-button
                    variant="danger"
                    class="delete-button"
                    @click="removeAudio(index)"
                  >{{ $t('Delete') }} ({{ index + 1 }})</b-button>
                </div>

                <!-- media -->
                <b-media no-body>
                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.audios.$el.click()"
                    >{{ $t('Upload') }}</b-button>
                    <b-form-file
                      ref="audios"
                      multiple
                      accept="audio/*"
                      :hidden="true"
                      plain
                      @change="inputAudiosRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetAudios"
                    >{{ $t('Reset') }}</b-button>
                    <!--/ reset -->

                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">

              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Name')"
                label-for="name"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_name`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="name[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">

              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Detail')"
                label-for="detail"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_detail`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="detail[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'detail')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Album')"
                label-for="album"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_album`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="album[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'album')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Genre')"
                label-for="genre"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_genre`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="genre[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'genre')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Categories')"
                label-for="categories"
              >
                <v-select
                  v-model="categories"
                  :options="dataCategoy"
                  :reduce="dataCategoy => dataCategoy._id"
                  label="name"
                  multiple
                  :clearable="false"
                  :close-on-select="false"
                  :searchable="false"
                  :placeholder="$t('Select') + ' ' + $t('Categories')"
                />
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import draggable from 'vuedraggable'
import storeModuleCategory from '@/store/services/eCommerce/audioCategory'

const STORE_MODULE_NAME_CATEGORY = 'audioCategory'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    draggable,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      images: [],
      audios: [],
      name: [],
      detail: [],
      album: [],
      genre: [],
      price: '0',
      categories: [],
      required,
    }
  },
  // images, audios, name, detail, price, categories
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    dataCategoy() {
      return store.state[STORE_MODULE_NAME_CATEGORY].respData != null
        ? store.state[STORE_MODULE_NAME_CATEGORY].respData.data.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.album.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.album.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.album.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.genre.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.genre.findIndex(
                element => element.lang === this.language[index].lang,
              )

              if (indexLanguage < 0) {
                this.genre.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id, images, audios, name, detail, album, genre, price, categories,
          } = this.data
          this.dataId = _id
          this.images = images
          this.audios = audios
          this.price = price
          this.categories = categories

          this.name = name
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }

          this.detail = detail
          if (this.detail.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.detail.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.detail.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.detail.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.detail[index].lang,
              )
              if (indexLanguage < 0) {
                this.detail.splice(index, 1)
              }
            }
          }

          this.album = album
          if (this.album.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.album.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.album.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.album.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.album[index].lang,
              )
              if (indexLanguage < 0) {
                this.album.splice(index, 1)
              }
            }
          }

          this.genre = genre
          if (this.genre.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.genre.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.genre.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.genre.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.genre[index].lang,
              )
              if (indexLanguage < 0) {
                this.genre.splice(index, 1)
              }
            }
          }
        }
      }
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME_CATEGORY)) store.registerModule(STORE_MODULE_NAME_CATEGORY, storeModuleCategory)
    this.getCategory()
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    getCategory() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: 0,
        searchQuery: '',
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_CATEGORY}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'detail') {
        if (this.detail[index].lang === lang) {
          this.detail[index].value = input
        }
      } else if (type === 'album') {
        if (this.album[index].lang === lang) {
          this.album[index].value = input
        }
      } else if (type === 'genre') {
        if (this.genre[index].lang === lang) {
          this.genre[index].value = input
        }
      }
    },
    removeAudio(index) {
      this.audios.splice(index, 1)
    },
    removeImage(index) {
      this.images.splice(index, 1)
    },
    inputAudiosRenderer(input) {
      const result = this.audios
      if (input.target.files.length > 0) {
        const { files } = input.target
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          const reader = new FileReader()

          reader.onload = function (e) {
            const contents = e.target.result
            result.push(contents)
          }

          reader.readAsDataURL(file)
        }
      }
      this.audios = result
    },
    inputImagesRenderer(input) {
      const result = this.images
      if (input.target.files.length > 0) {
        const { files } = input.target
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          const reader = new FileReader()

          reader.onload = function (e) {
            const contents = e.target.result
            result.push(contents)
          }

          reader.readAsDataURL(file)
        }
      }
      this.images = result
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetAudios() {
      this.audios = []
    },
    resetImage() {
      this.images = []
    },
    initValues() {
      this.dataId = null
      this.images = []
      this.audios = []
      this.name = []
      this.detail = []
      this.album = []
      this.genre = []
      this.price = ''
      this.categories = []
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            images: this.images,
            audios: this.audios,
            name: this.name,
            detail: this.detail,
            album: this.album,
            genre: this.genre,
            price: this.price,
            categories: this.categories,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)
                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.btn-remove {
  position: absolute;
  top: 20%;
  left: 30%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}
.imgs-show {
  // width: 100%;
  height: 100px;
}

.audio-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* จับขนาด audio ให้แน่นอน */
.audio-item audio {
  height: 40px;
}

/* ปรับปุ่มให้สูงเท่ากัน และอยู่ตรงกลาง */
.delete-button {
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 6px;
  background-color: #e74c3c;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c0392b;
}

</style>
